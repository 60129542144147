<script setup lang="ts">
  import { EngineStore } from '~/stores/engine'
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { PlanType } from '~/generated/api-clients-generated'
  import QuoteCardCosts from '~/composables/QuoteCardCosts'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const { getId } = Id.use(props.id)
  const { flag } = FeatureFlags.use()
  const { show } = DialogController.use()
  const { isMobile } = Breakpoints.use()
  const { maybeDo } = ActionGuard.use()

  const engine = EngineStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const costEssentialsSidebar = computed(
    () => flag<boolean>('quote-card-cost-essentials-sidebar').value
  )

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)

  const { pdpEmpty, pdpFilled, mapdEmpty, mapdFilled } = QuoteCardCosts.use(props.quote)

  const displayedCosts = computed(() =>
    emptyState.value
      ? isMapd.value
        ? mapdEmpty.value
        : pdpEmpty.value
      : isMapd.value
      ? mapdFilled.value
      : pdpFilled.value
  )
</script>

<template>
  <section>
    <header class="content-section-header bg-green-300">
      <div>
        <HpIcon name="money" class="text-primary-600" />
        <h2>Your Costs</h2>
      </div>
      <QuoteCardCostEssentialsSidebar
        :quote="quote"
        :empty-state="emptyState!"
        :id="getId(`cost-essentials-sidebar`)"
        v-if="isMapd && costEssentialsSidebar"
      />
    </header>
    <dl class="content-section-data">
      <div class="content-row" v-for="cost in displayedCosts" :key="cost.key">
        <dt>
          <PxTooltip :text="cost.label" :name="cost.tooltip" />
          <div
            class="flex"
            v-if="
              !isMobile &&
              quote.drugCoverage.coverageSummaries.length > 0 &&
              cost.key === 'estimated_yearly_drug_costs'
            "
          >
            <HpAnchor class="font-semibold" @click.prevent="show(getId('drug-breakdown-sidebar'))"
              >See Breakdown
            </HpAnchor>
          </div>
        </dt>
        <dl
          v-if="
            ['estimated_yearly_drug_costs', 'yearly_premium_plus_drug_costs'].includes(cost.key) &&
            quote.drugCoverage.coverageSummaries.length < 1
          "
        >
          <HpAnchor
            class="action-link shrink-icon"
            @click.prevent="() => maybeDo(() => show('update-drugs-sidebar'))"
          >
            <HpIcon name="add" class="text-primary-600" />
            Add Drugs
          </HpAnchor>
        </dl>
        <dl v-else>
          {{ _isFunction(cost.value) ? $formatUSD(cost.value(quote)) : $formatUSD(cost.value) }}
          <div
            class="flex"
            v-if="
              isMobile &&
              quote.drugCoverage.coverageSummaries.length > 0 &&
              cost.key === 'estimated_yearly_drug_costs'
            "
          >
            <HpAnchor class="font-semibold" @click.prevent="show(getId('drug-breakdown-sidebar'))"
              >See Breakdown
            </HpAnchor>
          </div>
        </dl>
      </div>
    </dl>
    <footer class="flex w-full justify-center py-3 md:py-0">
      <QuoteCardCostsSidebar
        :id="getId(`cost-breakdown-sidebar`)"
        :quote="quote"
        :empty-state="emptyState!"
      />
    </footer>
  </section>
  <DetailsDrugMonthlyBreakdownSidebar :quote="quote" :id="getId('drug-breakdown-sidebar')" />
</template>

<style scoped></style>
